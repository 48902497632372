import { mapMutations } from "vuex"

export const loginSignupMixin = {
  components: {
    Loading: () => import(/* webpackChunkName: "loading" */ "@/components/common/Loading.vue"),
  },
  data() {
    return {
      alertmsgs: this.$store.state.alertmsgs,
      email: "",
      password: "",
      valid: true,
      passwordFieldType: "password",
      loading: false,
      requiredRules: [(v) => !!v || "This field is required"],
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 6) || "Password must be atleast 6 characters",
        (v) => /^[A-Za-z0-9@#$%&]+$/.test(v) || "Only Alphabets, Digits and @#$%& allowed",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "Email must be valid",
      ],
    }
  },
  computed: {
    passwordIcon() {
      return this.passwordFieldType === "password" ? "$unmaskPassword" : "$maskPassword"
    },
  },
  methods: {
    ...mapMutations({
      setUser: "SET_CURRENT_USER",
      showAlert: "SHOW_SNACKBAR",
    }),
    unmaskPassword() {
      this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password"
    },
  },
}
