<template>
  <v-container class="forgot justify-center" fluid fill-height>
    <v-form ref="forgotForm" v-model="valid" lazy-validation @submit.prevent="forgotPassword()">
      <v-container class="forgot-inner background pa-sm-8 mx-auto">
        <v-row align="center" justify="center" class="pa-2">
          <v-col cols="12" lg="6" md="6">
            <lottie-player
              src="https://assets8.lottiefiles.com/packages/lf20_iwyr6aqu.json"
              background="transparent"
              speed="1"
              autoplay
              :style="$vuetify.breakpoint.smAndDown ? 'height: 30vh' : ''"
              loop
            ></lottie-player>
          </v-col>
          <v-col cols="12" lg="6" md="6">
            <h1 class="text-h4 justify-center secondary--text mb-5 font-weight-black">Forgot Password</h1>
            <v-text-field
              v-model="email"
              label="Enter reset email address"
              :rules="emailRules"
              filled
              clearable
              type="text"
              required
            ></v-text-field>
            <router-link class="secondary--text" text to="/login">Login?</router-link>
            <v-row align="center" class="mt-2">
              <v-col cols="12" md="6">
                <v-btn x-large block color="secondary" type="submit" :disabled="!valid"> Reset Password </v-btn>
              </v-col>
              <v-col cols="12" md="6">
                <v-btn x-large block color="secondary" outlined to="/login">
                  <font-awesome-icon icon="arrow-left" pull="left"></font-awesome-icon>
                  Go Back</v-btn
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <Loading :loading="loading" :text="forgotLoadingText" />
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import { loginSignupMixin } from "@/mixins/loginSignupMixin.js"
import { mapMutations } from "vuex"
import { resetPassword } from "@/api/AuthApi"

export default {
  mixins: [loginSignupMixin],
  data() {
    return {
      forgotLoadingText: "Sending reset link your mail id",
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "Email must be valid",
      ],
      snacktext: null,
      lottie: require("@/assets/lottie/forgot_password.json"),
    }
  },
  components: {
    Loading: () => import(/* webpackChunkName: "loading" */ "@/components/common/Loading.vue"),
  },

  methods: {
    ...mapMutations({ showAlert: "SHOW_SNACKBAR" }),

    async forgotPassword() {
      if (this.$refs.forgotForm.validate()) {
        const email = this.email
        this.loading = !this.loading
        try {
          await resetPassword(email)
          this.snacktext = "Check your registered email to reset password!"
          this.showAlert(this.snacktext)
          this.$router.push("/login", () => {})
        } catch (err) {
          this.snacktext = err
          this.showAlert(this.snacktext)
        } finally {
          this.loading = !this.loading
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.forgot {
  // background: rgb(255, 243, 224);
  // background:
  min-height: 90vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom left;
  background-image: url("~@/assets/background-bottom.svg"),
    linear-gradient(174deg, rgba(255, 243, 224, 1) 20%, rgba(255, 223, 173, 1) 78%);
  background-blend-mode: overlay;
  .forgot-inner {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  }
}
</style>
